import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import DynZone from "../components/DynZone"
import CommentsFB from "../components/Comments"
import { GatsbySeo } from "gatsby-plugin-next-seo"

const Notas = ({ data }) => (
  <Layout>
    <GatsbySeo
      title={data.strapi.nota.titulo}
      titleTemplate="%s | Eduardo Ceballos"
      description={data.strapi.nota.descripcion}
      canonical={`https://eduardoceballos.com/notas/${data.strapi.nota.uid}`}
      openGraph={{
        url: `https://eduardoceballos.com/notas/${data.strapi.nota.uid}`,
        title: data.strapi.nota.titulo,
        description: data.strapi.nota.descripcion,
        images: [
          {
            url: data.strapi.nota.portada ? data.strapi.nota.portada.url : "",
            alt: data.strapi.nota.portada ? data.strapi.nota.portada.alt : "",
          },
        ],
        site_name: "Eduardo Ceballos",
      }}
      twitter={{
        handle: "@PoetadeSalta",
        site: "@PoetadeSalta",
        cardType: "summary_large_image",
      }}
    />
    <h1>{data.strapi.nota.titulo}</h1>
    <DynZone components={data.strapi.nota.DynZone}></DynZone>
    <CommentsFB></CommentsFB>
  </Layout>
)

export const query = graphql`
  query Notas($id: ID!) {
    strapi {
      nota(id: $id) {
        uid
        titulo
        descripcion
        DynZone {
          ... on SP_ComponentComunesTexto {
            __typename
            id
            texto
          }
          ... on SP_ComponentComunesAutores {
            __typename
            id
            autores {
              id
              nombre
              foto {
                url
                caption
                alternativeText
                provider_metadata
              }
            }
          }
        }
      }
    }
  }
`

export default Notas
